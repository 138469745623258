import DiscordIcon from "../assets/images/discord.svg";
import TelegramIcon from "../assets/images/telegram.svg";
import TwitterIcon from "../assets/images/twitter.svg";
import Githubicon from "../assets/images/github.svg";

const Footer = () => {
  return (
    <>
      <footer className="tw-pt-16 tw-pb-5">
        <div className="stable-alignment tw-space-y-5">
          <div className="tw-text-center tw-space-y-2">
            <p className="tw-text-2xl tw-text-gray-700">
              Contact us support@boltprotocol.co
            </p>
            <p className="tw-text-sm tw-font-light tw-text-gray-500">
              Most secure tool for all wallets node correction featuring kyc and
              others interesting functions
            </p>
          </div>
          <ul
            className="
            tw-text-secondary tw-flex tw-space-y-4 tw-flex-col tw-items-center
          "
          >
            <a href="/" target="_blank">
              <li className="tw-flex tw-items-center tw-space-x-2">
                <div className="link-icon">
                  <img
                    src={DiscordIcon}
                    alt="discord"
                    className="tw-w-[20px]"
                  />
                </div>
                <span className="tw-font-medium">Discord</span>
              </li>
            </a>

            <a href="/" target="_blank">
              <li className="tw-flex tw-items-center tw-space-x-2">
                <div className="link-icon">
                  <img
                    src={TelegramIcon}
                    alt="telegram"
                    className="tw-w-[20px]"
                  />
                </div>
                <span className="tw-font-medium">Telegram</span>
              </li>
            </a>

            <a href="/" target="_blank">
              <li className="tw-flex tw-items-center tw-space-x-2">
                <div className="link-icon">
                  <img
                    src={TwitterIcon}
                    alt="twitter"
                    className="tw-w-[20px]"
                  />
                </div>
                <span className="tw-font-medium">Twitter</span>
              </li>
            </a>

            <a href="/" target="_blank">
              <li className="tw-flex tw-items-center tw-space-x-2">
                <div className="link-icon">
                  <img src={Githubicon} alt="github" className="tw-w-[20px]" />
                </div>
                <span className="tw-font-medium">Github</span>
              </li>
            </a>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;
